<template>
  <div v-if="$apollo.loading">Loading..</div>
  <div v-else class="container">
    <div class="display-2">Marketplace/ Store Settings</div>
    <div class="text-lg-right">
      <v-btn color="success" @click="save">
        Save
      </v-btn>
    </div>

    <div class="row mt-6">
      <div class="col-lg-3">
        <div class="display-1 ml-5"><strong> Bank Settings </strong></div>
      </div>
      <div class="col-lg-6">
        <v-btn @click="isBankDetails = true"> Update Bank Details</v-btn>
        <v-dialog v-model="isBankDetails" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Update Bank Details
            </v-card-title>

            <v-card-text>
              <div class="text-overline">
                Current Bank:
              </div>
              <div>
                {{ currentBankInfo }}
              </div>

              <hr />
              <div class="text-overline">
                New Bank Information:
              </div>
              <v-text-field
                v-model="updateBankInfo.routingNumber"
                label="Routing Number"
                :rules="rules"
              >
              </v-text-field>
              <v-text-field
                v-model="updateBankInfo.accountNumber"
                label="Account Number"
                :rules="rules"
              >
              </v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="onUpdateBankDetails">
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="row mt-6">
      <div class="col-lg-3">
        <div class="display-1 ml-5"><strong> Channel Settings </strong></div>
      </div>
      <div class="col-lg-6">
        <v-select
          v-model="defaultChannel"
          :items="channels"
          item-text="name"
          item-value="id"
          label="Default Channel"
        >
        </v-select>

        <v-select
          v-model="defaultPricelist"
          :items="pricelist"
          item-text="listname"
          item-value="id"
          label="Default Pricing List"
        ></v-select>
      </div>
    </div>

    <v-divider> </v-divider>
    <div class="row mt-5">
      <div class="col-lg-3">
        <div class="display-1 ml-5"><strong> Order Settings </strong></div>
      </div>
      <div class="col-lg-6">
        <v-text-field
          v-model="order_handling_time"
          type="number"
          label="Order Handling Time"
        >
        </v-text-field>
        <v-text-field
          v-model="shipping_fixed_rate"
          prepend-inner-icon="mdi-currency-usd"
          label="Shippping Fixed Rate"
          @keypress="vueValidateFloatKeyPress"
        ></v-text-field>
        <v-text-field
          v-model="minimumOrderAmount"
          prepend-inner-icon="mdi-currency-usd"
          label="Minimum Order Amount"
          @keypress="onlyForCurrency"
          >{{ minimumOrderAmount | dollar_format }}</v-text-field
        >
        <v-select
          v-model="shipping_fee_type"
          label="Fee per Item or Order"
          :items="['item', 'order']"
        ></v-select>

        <v-list-item-group multiple active-class="">
          <v-list-item>
            <div>
              <v-list-item-action>
                <v-switch
                  v-model="orderContact"
                  color="success"
                  hide-details
                ></v-switch>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Notifications</v-list-item-title>
                <v-list-item-subtitle
                  >Notify me about new orders by Email</v-list-item-subtitle
                >
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-item-group>
      </div>
    </div>

    <v-divider> </v-divider>
    <div class="row mt-6">
      <div class="col-lg-3">
        <div class="display-1 ml-5"><strong> Store Settings </strong></div>
      </div>

      <div class="col-lg-6">
        <v-checkbox
          v-model="isPublicStore"
          label="Public Store and Products"
        ></v-checkbox>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <div>
                <v-list-item-action>
                  <v-img
                    :src="
                      store_logo_url != ''
                        ? store_logo_url
                        : '/media/product-placeholder.jpg'
                    "
                    width="75"
                    height="75"
                  >
                  </v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Logo for Store</v-list-item-title>

                  <b-form-file
                    v-model="store_logo"
                    :state="Boolean(store_logo)"
                    :placeholder="
                      store_logo_name == '' ? 'Upload Image' : store_logo_name
                    "
                    drop-placeholder="Drop file here..."
                    accept="image/*"
                  ></b-form-file>
                </v-list-item-content>
              </div>
            </v-list-item>

            <v-list-item>
              <div>
                <v-list-item-action>
                  <v-img
                    :src="
                      order_logo_url != ''
                        ? order_logo_url
                        : '/media/product-placeholder.jpg'
                    "
                    width="75"
                    height="75"
                  >
                  </v-img>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >Logo for Orders/Invoices</v-list-item-title
                  >

                  <b-form-file
                    v-model="order_logo"
                    :state="Boolean(order_logo)"
                    :placeholder="
                      order_logo_name == '' ? 'Upload Image' : order_logo_name
                    "
                    drop-placeholder="Drop file here..."
                    accept="image/*"
                  ></b-form-file>
                </v-list-item-content>
              </div>
            </v-list-item>

            <v-list-item>
              <div>
                <v-list-item-action>
                  <v-img
                    :src="
                      store_banner_url != ''
                        ? store_banner_url
                        : '/media/product-placeholder.jpg'
                    "
                    width="75"
                    height="75"
                  >
                  </v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Top Banner for Store</v-list-item-title>

                  <b-form-file
                    v-model="store_banner"
                    :state="Boolean(store_banner)"
                    :placeholder="
                      store_banner_name == ''
                        ? 'Upload Image'
                        : store_banner_name
                    "
                    drop-placeholder="Drop file here..."
                    accept="image/*"
                  ></b-form-file>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <div class="row">
          <div class="col-lg-12">
            <div class="display-2 mt-4">Address to appear on Invoices</div>
          </div>

          <div class="col-lg-12">
            <v-text-field
              v-model="orderAddress1"
              label=" Address line 1"
            ></v-text-field>
          </div>

          <div class="col-lg-6">
            <v-text-field v-model="orderCity" label="City"></v-text-field>
          </div>
          <div class="col-lg-6">
            <v-select
              v-model="orderState"
              item-text="name"
              item-value="id"
              :items="states"
              label="State "
            >
            </v-select>
          </div>

          <div class="col-lg-6">
            <v-text-field v-model="orderZip" label="Zip"></v-text-field>
          </div>
          <div class="col-lg-12">
            <v-text-field v-model="orderEmail" label="email"></v-text-field>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="display-2 mt-4">Return Policy</div>
        </div>

        <v-textarea
          v-model="order_return_policy"
          class="mt-4"
          outlined
          label="Order Return Policy"
        >
        </v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import UpdateBankDetails from '@/graphql/mutations/UpdateBankDetails.gql'
import UploadFile from '@/graphql/mutations/UploadFile.gql'
import UpdateCompany from '@/graphql/mutations/UpdateCompany.gql'
import BalanceVendorPayment from '@/graphql/queries/BalanceVendorPayment.gql'
import GetChannelsBySupplier from '@/graphql/queries/GetChannelsBySupplier.gql'
export default {
  filters: {
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
      isBankDetails: false,
      rules: [(value) => (!!value && value !== '') || 'Required.'],
      updateBankInfo: {
        routingNumber: '',
        accountNumber: '',
      },

      order_logo: null,
      store_logo_id: '',
      store_logo_url: '',
      store_logo: null,
      store_banner_id: '',
      store_banner: null,
      store_banner_url: '',
      order_logo_url: '',
      orderEmail: '',
      orderPhone: '',
      orderAddress1: '',
      orderAddress2: '',
      orderCity: '',
      orderContact: '',
      orderState: '',
      orderZip: '',
      order_logo_name: '',
      order_logo_id: '',
      orderLogoId: undefined,
      storeBannerId: undefined,
      storeLogoId: undefined,
      store_logo_name: '',
      order_return_policy: '',
      store_banner_name: '',
      order_handling_time: 0,
      shipping_fixed_rate: 0,
      minimumOrderAmount: 0,
      shipping_fee_type: 'item',
      accept_credit_card: false,
      allow_net_terms: false,
      allow_over_credit_limit: false,
      defaultChannel: '',
      defaultPricelist: '',
      isPublicStore: false,
      states: [
        { name: 'Alabama', id: 'AL' },
        { name: 'Alaska', id: 'AK' },
        { name: 'Arizona', id: 'AZ' },
        { name: 'Arkansas', id: 'AR' },
        { name: 'California', id: 'CA' },
        { name: 'Colorado', id: 'CO' },
        { name: 'Connecticut', id: 'CT' },
        { name: 'Delaware', id: 'DE' },
        { name: 'District of Columbia', id: 'DC' },
        { name: 'Florida', id: 'FL' },
        { name: 'Georgia', id: 'GA' },
        { name: 'Hawaii', id: 'HI' },
        { name: 'Idaho', id: 'ID' },
        { name: 'Illinois', id: 'IL' },
        { name: 'Indiana', id: 'IN' },
        { name: 'Iowa', id: 'IA' },
        { name: 'Kansa', id: 'KS' },
        { name: 'Kentucky', id: 'KY' },
        { name: 'Lousiana', id: 'LA' },
        { name: 'Maine', id: 'ME' },
        { name: 'Maryland', id: 'MD' },
        { name: 'Massachusetts', id: 'MA' },
        { name: 'Michigan', id: 'MI' },
        { name: 'Minnesota', id: 'MN' },
        { name: 'Mississippi', id: 'MS' },
        { name: 'Missouri', id: 'MO' },
        { name: 'Montana', id: 'MT' },
        { name: 'Nebraska', id: 'NE' },
        { name: 'Nevada', id: 'NV' },
        { name: 'New Hampshire', id: 'NH' },
        { name: 'New Jersey', id: 'NJ' },
        { name: 'New Mexico', id: 'NM' },
        { name: 'New York', id: 'NY' },
        { name: 'North Carolina', id: 'NC' },
        { name: 'North Dakota', id: 'ND' },
        { name: 'Ohio', id: 'OH' },
        { name: 'Oklahoma', id: 'OK' },
        { name: 'Oregon', id: 'OR' },
        { name: 'Pennsylvania', id: 'PA' },
        { name: 'Rhode Island', id: 'RI' },
        { name: 'South Carolina', id: 'SC' },
        { name: 'South Dakota', id: 'SD' },
        { name: 'Tennessee', id: 'TN' },
        { name: 'Texas', id: 'TX' },
        { name: 'Utah', id: 'UT' },
        { name: 'Vermont', id: 'VT' },
        { name: 'Virginia', id: 'VA' },
        { name: 'Washington', id: 'WA' },
        { name: 'West Virginia', id: 'WV' },
        { name: 'Wisconsin', id: 'WI' },
        { name: 'Wyoming', id: 'WY' },
      ],
      pricelist: [],
    }
  },
  apollo: {
    balanceVendor: {
      query: BalanceVendorPayment,
    },
    channels: {
      query: GetChannelsBySupplier,
      variables() {
        return {
          supplierId: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },
  computed: {
    currentBankInfo() {
      if (
        !this.balanceVendor ||
        !this.balanceVendor.paymentData ||
        !this.balanceVendor.paymentData.banks ||
        !this.balanceVendor.paymentData.banks[0]
      ) {
        return ''
      }
      return this.balanceVendor.paymentData.banks[0].institutionName || ''
    },
    orderAmount() {
      if (!this.minimumOrderAmount) {
        return 0
      } else {
        return this.minimumOrderAmount
      }
    },
  },
  created() {
    setTimeout(() => {
      this.loadData()
    }, 1000)
  },
  methods: {
    ...mapActions([
      'getChannels',
      'getCompanyInfo',
      'updateCompanyInfo',
      'getPricelist',
    ]),
    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.minimumOrderAmount.indexOf('.') != -1)
      ) {
        // 46 is dot
        $event.preventDefault()
      }

      // restrict to 2 decimal places
      if (
        this.minimumOrderAmount != null &&
        this.minimumOrderAmount.indexOf('.') > -1 &&
        this.minimumOrderAmount.split('.')[1].length > 1
      ) {
        $event.preventDefault()
      }
    },
    loadData() {
      // this.getChannels({
      //   supplier: this.$store.state.auth.user.company_relation,
      // }).then((res) => {
      //   this.channels = res.data
      // })
      this.getPricelist().then((pres) => {
        this.pricelist = pres.data
      })
      this.getCompanyInfo({
        id: this.$store.state.auth.user.company_relation,
      }).then((cres) => {
        if (cres.data.default_channel != null) {
          this.defaultChannel = String(cres.data.default_channel.id)
        }
        if (cres.data.default_pricelist != null) {
          this.defaultPricelist = cres.data.default_pricelist.id
        }
        this.accept_credit_card = cres.data.accept_credit_card
        this.orderEmail = cres.data.orderEmail
        this.orderAddress1 = cres.data.orderAddress1
        this.orderAddress2 = cres.data.orderAddress2
        this.orderCity = cres.data.orderCity
        this.orderState = cres.data.orderState
        this.orderZip = cres.data.orderZip
        this.orderPhone = cres.data.orderPhone
        this.orderContact = cres.data.orderContact
        this.allow_net_terms = cres.data.allow_net_terms
        this.allow_over_credit_limit = cres.data.allow_over_credit_limit
        this.order_handling_time = cres.data.order_handling_time
        this.order_return_policy = cres.data.order_return_policy
        this.shipping_fixed_rate = cres.data.shipping_fixed_rate
        this.minimumOrderAmount = cres.data.minimumOrderAmount
        this.isPublicStore =
          cres.data.isPublicStore === null ||
          cres.data.isPublicStore === undefined
            ? false
            : cres.data.isPublicStore
        if (cres.data.shipping_fee_type != null) {
          this.shipping_fee_type = cres.data.shipping_fee_type
        }
        if (cres.data.order_logo != null) {
          this.order_logo_id = cres.data.order_logo.id
          this.order_logo_url = cres.data.order_logo.url
          this.order_logo_name = cres.data.order_logo.name
        }
        if (cres.data.store_logo != null) {
          this.store_logo_id = cres.data.store_logo.id
          this.store_logo_url = cres.data.store_logo.url
          this.store_logo_name = cres.data.store_logo.name
        }
        if (cres.data.store_banner != null) {
          this.store_banner_id = cres.data.store_banner.id
          this.store_banner_url = cres.data.store_banner.url
          this.store_banner_name = cres.data.store_banner.name
        }
      })
    },
    logoChange() {},
    vueValidateFloatKeyPress($event) {
      var el = $event.path[0]
      var evt = $event
      var charCode = evt.which ? evt.which : event.keyCode
      var number = el.value.split('.')
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        $event.preventDefault()
      }
      //just one dot
      if (number.length > 1 && charCode == 46) {
        $event.preventDefault()
      }
      //get the carat position
      var caratPos = this.getSelectionStart(el)
      var dotPos = el.value.indexOf('.')
      if (caratPos > dotPos && dotPos > -1 && number[1].length > 1) {
        $event.preventDefault()
      }
    },
    getSelectionStart(o) {
      if (o.createTextRange) {
        var r = document.selection.createRange().duplicate()
        r.moveEnd('character', o.value.length)
        if (r.text == '') return o.value.length
        return o.value.lastIndexOf(r.text)
      } else return o.selectionStart
    },
    async save() {
      if (this.store_logo) {
        const storeLogo = await this.$apollo.mutate({
          // Query
          mutation: UploadFile,
          // Parameters
          variables: {
            file: this.store_logo,
          },
        })
        this.storeLogoId = storeLogo.data.upload.id
      }
      if (this.store_banner) {
        const storeBanner = await this.$apollo.mutate({
          // Query
          mutation: UploadFile,
          // Parameters
          variables: {
            file: this.store_banner,
          },
        })
        this.storeBannerId = storeBanner.data.upload.id
      }
      if (this.order_logo) {
        const orderLogo = await this.$apollo.mutate({
          // Query
          mutation: UploadFile,
          // Parameters
          variables: {
            file: this.order_logo,
          },
        })
        this.orderLogoId = orderLogo.data.upload.id
      }

      // TODO use a graphql mutation and delete and files that are being replaced.
      if (this.defaultChannel !== '' || this.defaultPricelist !== '') {
        await this.$apollo.mutate({
          // Query
          mutation: UpdateCompany,
          // Parameters
          variables: {
            id: this.$store.state.auth.user.company_relation,
            oderLogoId: this.orderLogoId,
            storeBannerId: this.storeBannerId,
            storeLogoId: this.storeLogoId,
            defaultChannel: parseInt(this.defaultChannel),
            defaultPriceList: this.defaultPricelist,
            acceptCreditCard: this.accept_credit_card,
            allowNetTerms: this.allow_net_terms,
            allowOverCreditLimit: this.allow_over_credit_limit,
            orderHandlingTime: parseInt(this.order_handling_time),
            shippingFixedRate: parseFloat(this.shipping_fixed_rate),
            shippingFeeType: this.shipping_fee_type,
            minimumOrderAmount: parseFloat(this.orderAmount),
            orderEmail: this.orderEmail,
            orderPhone: this.orderPhone,
            orderContact: this.orderContact,
            orderAddress1: this.orderAddress1,
            orderAddress2: this.orderAddress2,
            orderCity: this.orderCity,
            orderState: this.orderState,
            orderZip: this.orderZip,
            order_return_policy: this.order_return_policy,
            isPublicStore: this.isPublicStore,
          },
        })
        // await this.updateCompanyInfo({
        //   id: this.$store.state.auth.user.company_relation,
        //   default_channel: this.defaultChannel,
        //   default_pricelist: this.defaultPricelist,
        //   accept_credit_card: this.accept_credit_card,
        //   allow_net_terms: this.allow_net_terms,
        //   allow_over_credit_limit: this.allow_over_credit_limit,
        //   order_handling_time: this.order_handling_time,
        //   shipping_fixed_rate: this.shipping_fixed_rate,
        //   shipping_fee_type: this.shipping_fee_type,
        //   minimumOrderAmount: this.orderAmount,
        // order_logo: this.order_logo,
        //   orderEmail: this.orderEmail,
        //   orderPhone: this.orderPhone,
        //   orderContact: this.orderContact,
        //   orderAddress1: this.orderAddress1,
        //   orderAddress2: this.orderAddress2,
        //   orderCity: this.orderCity,
        //   orderState: this.orderState,
        //   orderZip: this.orderZip,
        //   // store_logo: this.store_logo,
        //   // store_banner: this.store_banner,
        //   order_return_policy: this.order_return_policy,
        //   isPublicStore: this.isPublicStore,
        // })
        Swal.fire({
          title: '',
          text: 'Updated successfully.',
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary',
        })
        this.loadData()
      }
    },
    async onUpdateBankDetails() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateBankDetails,
          variables: this.updateBankInfo,
        })
        await Swal.fire({
          title: '',
          text: 'Bank Account info Saved.',
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary',
        })
        this.$apollo.queries.balanceVendor.refetch()
        this.isBankDetails = false
      } catch (error) {
        await Swal.fire({
          title: '',
          text: 'Error, please try again.',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
        // console.log(error)
      }
    },
  },
}
</script>
<style></style>
